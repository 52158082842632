import * as React from 'react'
import OnboardingLayout from '../../layout/OnboardingLayout'
import SEO from '../../components/seo'
import OnboardingApp from '../../components/onboarding/onboardingApp'
import { pushPageViewPreventDuplicates, startMembershipApplication } from 'utils/gtmUtils'

const OnboardingTemplate = ({ pageContext, data, location }) => {
  React.useEffect(() => {
    pushPageViewPreventDuplicates()
    startMembershipApplication()
  }, [])

  return (
    <OnboardingLayout>
      <SEO
        description="Bli medlem i DIK och få tillgång till massor av kunskap och ett gäng dedikerade människor står redo att coacha dig på din väg framåt."
        title="Bli medlem"
      />
      <OnboardingApp />
    </OnboardingLayout>
  )
}

export default OnboardingTemplate
